const DATA = [
  {
    id: 0,
    title: "Мегафон 66FS",
    subTitle:
      "🎉Это один из самых мощных и самых функциональных мегафонов на 50ват. Данная модель рупора оснащена функцией USB. Вам надо усиливать голос то етот мегафон незаменим для использования на  🎤 рекламных акциях, особенно при движении на 🚚 автотранспорте. Также отлично подходит для профессиональной работы:  🚔милиции, 🚒 пожарных и 🏖спасателей.",
    price: 27000,
    desc: "⌚️ Время работы от аккумулятора до 10 часов!!!",
    desc1:
      "🧑‍💻 Технические характеристики рупора 25W максимальный мощность 50W:",
    desc2:
      "- Рупорная акустическая система 50 Вт Макс, всепогодная, однополосная.",
    desc3: "- Кнопочное управление мегафоном.",
    desc4: "- Дальность звучания: до 1000 метров.",
    desc5:
      "- Питание: 🔋 съемный аккумулятор зарядка от сети 220 (в комплект входит).",
    workDesc: "- Материал: прочный ABS пластик.",
    wd: "Режимы работы:",
    wd1: "🚨 режима сигнала,",
    wd2: "📣 Режим USB воспроизведения,",
    wd3: "🗣 разговор,",
    wd4: "⏺ запись и ▶️ воспроизведение (Максимальная запись: 120 секунд)",
    size: "🎶 режим мелодии,",
    sized: "🎚 регулировка громкости.",
    sized1: "Размеры:",
    sized2: "Длинна - 35мм",
    sized3: "Вес: 950 г",
    sized4: "Цвет: белый с красным.",
    sized5: "Комплектация:",
    sized6: "Мегафон 50W.",
    sized7: "Қол бауы",
    sized8: "Съемный аккумулятор c вилкой",
    sized9: "Ремень для ношения в руке.",
    sized10: "Картонная упаковка.",
    img: "/assets/images/home/11.jpeg",
  },
  {
    id: 1,
    title: "Мегафон MF-77ZOR",
    subTitle:
      '🎉Это один из самых мощных и самых функциональных мегафонов на 50ват. Данная модель рупора оснащена функцией USB, запись и повторения 240 с, кабель для прикуривателя. Одноразовые   8 штук "С" тип батарейки дорого обходится при ежедневном использовании поэтому добавлено аккумулятор. Если аккумулятор сел при движении можете воспользоваться шнуром для прикуривателя с помощью 6 метрового кабеля.Вам надо усиливать голос то етот мегафон незаменим для использования на 🎤 рекламных акциях, особенно при 🚚 движении на автотранспорте. Также отлично подходит для профессиональной работы:  🚔милиции,  🚒пожарных и 🏖спасателей и даже для фудбола.',
    price: 28000,
    desc: "⌚️Время работы от аккумулятора до 10 часов!!! 🧑‍💻Технические характеристики рупора 25W максимальный мощность 50W:",
    desc1:
      "- Рупорная акустическая система 50 Вт Макс, всепогодная, однополосная.",
    desc2: "- Кнопочное управление мегафоном.",
    desc3: "- Дальность звучания: до 800 метров.",
    desc4:
      "- Питание: 🔋съемный аккумулятор зарядка от сети 220 (в комплект входит).",
    desc5: "- Материал: прочный ABS пластик.",
    workDesc: "Режимы работы:",
    wd: "🚨 режима сигнала",
    wd1: "разговор",
    wd2: "запись и воспроизведение (Максимальная запись: 120 секунд)",
    wd3: "режим мелодии",
    wd4: "регулировка громкости.",
    size: "Размеры:",
    sized: "Длинна - 35мм",
    sized1: "Диаметр орала - 230мм",
    sized2: "Вес:  1,5 кг.",
    sized3: "Цвет: белый с синим.",
    sized4: "Комплектация:",
    sized5: "Мегафон 50W.",
    sized6: "Съемный аккумулятор c вилкой 12 в.",
    sized7: "Ремень для ношения в руке",
    sized8: "Кабель для прикуривателя",
    sized9: "Инструкция (рус/каз)",
    sized10: "Картонная упаковка.",
    img: "/assets/images/home/77.jpeg",
  },
];

export default DATA;
