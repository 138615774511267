const DATA = [
  {
    id: 0,
    title: "Мегафон 66FS",
    subTitle:
      "🎉Бұл ең қуатты және ең функционалды 50 Вт мегафондардың бірі. Бұл мүйізді модель USB функциясымен жабдықталған. Даусыңызды күшейту қажет болса, бұл мегафон 🎤 жарнамалық акцияларда, әсіресе 🚚 көліктерді басқару кезінде пайдалану үшін таптырмас. . Сондай-ақ кәсіби жұмыс үшін тамаша: 🚔полиция, 🚒 өрт сөндірушілер және 🏖құтқарушылар.",
    price: 28000,
    desc: "⌚️ Батареяның қызмет ету мерзімі 10 сағатқа дейін!!!",
    desc1: "🧑‍💻 Мүйіз спецификациясы 25 Вт максимум қуат 50 Вт:",
    desc2:
      "- Кернейлі динамик жүйесі 50 Вт Макс, кез келген ауа-райында, 1 жақты.",
    desc3: "- Мегафон түймешігін басқару.",
    desc4: "- Дыбыс диапазоны: 1000 метрге дейін.",
    desc5:
      "- Қуат көзі: 🔋 электр желісінен 220 алынбалы батареяны зарядтау (жинаққа кіреді).",
    workDesc: "- Материал: берік ABS пластик.",
    wd: "Жұмыс режимдері:",
    wd1: "🚨 сигнал режимі,",
    wd2: "📣 USB ойнату режимі,",
    wd3: "🗣 әңгіме,",
    wd4: "⏺ жазу және ▶️ ойнату (ең көп жазу: 120 секунд)",
    size: "🎶 әуен режимі:",
    sized: "🎚 дыбыс деңгейін басқару.",
    sized1: "Өлшемдері",
    sized2: "Ұзындығы - 35 мм",
    sized3: "Салмағы: 950г",
    sized4: "Түс: ақ және қызыл:",
    sized5: "Өлшем",
    sized6: "Мегафон 50 Вт",
    sized7: "Көл бауы",
    sized8: "Штепсельдік батареясы бар алынбалы батарея",
    sized9: "Қол бауы",
    sized10: "Картон пакеті.",
    img: "/assets/images/home/11.jpeg",
  },
  {
    id: 1,
    title: "Мегафон MF-77ZOR",
    subTitle:
      "🎉 Бұл ең қуатты және ең функционалды 50 ватт мегафондардың бірі. Бұл мүйіз үлгісі USB функциясымен, 240 с жазу және қайталау, темекі тұтқышы кабелімен жабдықталған. Бір рет қолданылатын 8 дана «C» типті батареялар күнделікті пайдалану үшін қымбат, сондықтан батарея қосылады. Көлік жүргізу кезінде батарея заряды таусылған болса, 6 метрлік кабель арқылы темекі тұтқышының сымын пайдалануға болады.Егер сізге дауысты күшейту қажет болса, бұл мегафон 🎤 акцияларда, әсіресе көлік жүргізу кезінде 🎤 пайдалану үшін таптырмас. Сондай-ақ кәсіби жұмыс үшін тамаша: 🚔полиция, 🚒өрт сөндірушілер және 🏖құтқарушылар, тіпті фудбол үшін.",
    price: 28000,
    desc: "Батареяның қызмет ету мерзімі 10 сағатқа дейін!!! Horn 25W макс қуаты 50Вт техникалық сипаттамалары:",
    desc1:
      "- Кернейлі динамик жүйесі 50 Вт Макс, кез келген ауа-райында, 1 жақты.",
    desc2: "- Мегафонды басқару түймесі.",
    desc3: "- Дыбыс диапазоны: 800 метрге дейін.",
    desc4:
      "- Азық-түлік: 🔋желіден зарядталатын алынбалы аккумулятор 220 (пакетке кіреді).",
    desc5: "- Материал: берік ABS пластик.",
    workDesc: "Жұмыс режимдері:",
    wd: "🚨 сигнал режимі",
    wd1: "әңгіме",
    wd2: "жазу және ойнату (Максималды жазу: 120 секунд)",
    wd3: "әуен режимі",
    wd4: "дыбыс деңгейін бақылау.",
    size: "Өлшемдері:",
    sized: "Ұзындығы - 35мм",
    sized1: "Соқаның диаметрі - 230мм",
    sized2: "Салмағы:  1,5 кг.",
    sized3: "Түсі: ақ және көк.",
    sized4: "Жабдық:",
    sized5: "Мегафон 50W.",
    sized6: "12 В ашасы бар алынбалы батарея.",
    sized7: "Қол бауы",
    sized8: "Шылым шегетін кабель",
    sized9: "Нұсқау (рус/каз)",
    sized10: "Картоннан жасалған қаптама.",
    img: "/assets/images/home/77.jpeg",
  },
];

export default DATA;
